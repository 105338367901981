import "./Checkout.css";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Checkout.css";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  removeFromCart,
  removeSingleElement,
  removeCoupon,
  addCustomer,
} from "../../redux/features/cartSlice";
import {
  addToCart,
  applyDiscount,
  changeDiscount,
} from "../../redux/features/cartSlice";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import Loader from "./Loader";
function CheckOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const { carts, subtotal, subtotalAfterDiscount, discountAmount } =
    useSelector(state => state.allCart);
  const [couponApplied, setCouponApplied] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const handleRemoveFromCart = id => {
    dispatch(removeFromCart(id, couponApplied));
  };
  const handleDecreaseCart = e => {
    dispatch(removeSingleElement(e));
  };
  const send = (e, discount, couponApplied) => {
    dispatch(addToCart(e));
  };

  const [error, setError] = useState({});
  const [shippingCost, setShippingCost] = useState(0);
  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka",
    "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram",
    "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu",
    "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal",
    "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi", "Jammu and Kashmir", "Ladakh", "Lakshadweep", "Puducherry"
  ];


  // const [discount, setDiscount] = useState(0);

  const [couponCode, setCouponCode] = useState("");
  // console.log(discount);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    flatHouseNo: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    famousLandmark: "",
  });

  useEffect(() => {
    calculateShippingCost(formData.state, subtotal);
  }, [formData.state, subtotal]);

  const calculateShippingCost = (state, subtotal) => {
    if (state === "Tamil Nadu") {
      if (subtotal >= 3000) {
        setShippingCost(0);
      } else {
        setShippingCost(60);
      }
    } else if (state) {
      setShippingCost(100);
    } else {
      setShippingCost(0); // Default when no state is selected
    }
  };


  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone Number must be 10 digits")
      .required(),
    flatHouseNo: Yup.string().required("Flat/House no. is Required"),
    address: Yup.string().required("Address is Required"),
    city: Yup.string().required("City is Required"),
    state: Yup.string().required("State Name is Required"),
    postalCode: Yup.string().required("Postal Code is Required"),
    famousLandmark: Yup.string().required("Postal Code is Required"),
  });

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "+91",
      phoneNumber: "",
      flatHouseNo: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      famousLandmark: "",
    });
  };
  const onChangeHandler = e => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
  };
  // const checkoutHandler = async () => {
  //   setLoading(true);
  //   try {
  //     setError({});
  //     await validationSchema.validate(formData, { abortEarly: false });

  //     const customerResponse = await axios.post(
  //       `${BASE_URL}${API_ENDPOINTS.addCustomer}`,
  //       formData
  //     );

  //     if (customerResponse.data.successCode !== 1) {
  //       throw new Error("Failed to create customer");
  //     }
  //     const customerId = customerResponse.data.result._id;
  //     const response = await axios.post(
  //       `${BASE_URL}${API_ENDPOINTS.createOrder}`,
  //       {
  //         productId: carts.map(item => item._id),
  //         subtotal: subtotal,
  //       }
  //     );
  //     if (response.data.successCode === 1) {
  //       // const { id, amount, currency } = response.data.order;
  //       setOrder(response.data.order);
  //     }

  //     const options = {
  //       key_id: process.env.RAZORPAY_KEY,
  //       amount: subtotal * 100,
  //       currency: "INR",
  //       name: "Noura Skin Care",
  //       description: "Skin Care",
  //       order_id: order.id,
  //       handler: async function (response) {
  //         try {
  //           const addSalesResponse = axios.post(
  //             `${BASE_URL}${API_ENDPOINTS.addSales}`,
  //             {
  //               razorpay_payment_id: response.razorpay_payment_id,
  //               razorpay_order_id: response.razorpay_order_id,
  //               razorpay_signature: response.razorpay_signature,
  //               productId: carts.map(item => item._id),
  //               customerId: customerId,
  //               quantity: carts.reduce((acc, item) => acc + item.quantity, 0),
  //               price: subtotal,
  //             }
  //           );

  //           resetForm();

  //           dispatch(addCustomer(customerResponse.data.result));
  //           navigate("/confirmation");
  //         } catch (error) {
  //           console.error("Error creating sales:", error);
  //           setLoading(false);
  //           toast.error("Payment is Failed");
  //         } finally {
  //           setLoading(false); // Hide the loading indicator
  //         }
  //       },
  //       prefill: {
  //         name: formData.firstName + " " + formData.lastName,
  //         email: formData.email,
  //         contact: formData.countryCode + formData.phoneNumber,
  //       },
  //       notes: {
  //         address: "Your Company Address",
  //       },
  //       theme: {
  //         color: "#21b65de4",
  //       },
  //     };

  //     const razor = new window.Razorpay(options);
  //     razor.open();
  //     setLoading(false);
  //   } catch (err) {
  //     const newErrors = {};

  //     if (err.inner && Array.isArray(err.inner)) {
  //       err.inner.forEach(err => {
  //         newErrors[err.path] = err.message;
  //       });
  //     } else {
  //       newErrors.general = err.message || "Validation failed";
  //     }

  //     setError(newErrors);
  //     setLoading(false);
  //   }
  // };
  const initializeRazorpay = (order, customerResponse) => {
    const productData = carts.map((item) => ({
        productId: item._id,
        quantity: item.quantity,
    }));

    const options = {
        key_id: process.env.RAZORPAY_KEY,
        amount: subtotal * 100,
        currency: "INR",
        name: "Noura Skin Care",
        description: "Skin Care",
        order_id: order.id,
        handler: async function (response) {
            try {
                const payload = {
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                    products: productData,
                    customerId: customerResponse.data.result._id,
                    quantity: carts.reduce((acc, item) => acc + item.quantity, 0),
                    price: subtotal,
                };

                console.log("Payload sent to BE:", payload);

                await axios.post(`${BASE_URL}${API_ENDPOINTS.addSales}`, payload);

                resetForm();
                dispatch(addCustomer(customerResponse.data.result));
                navigate("/confirmation");
            } catch (error) {
                console.error("Error creating sales:", error);
                toast.error("Payment failed! Please try again.");
            } finally {
                setLoading(false);
            }
        },
        prefill: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            contact: `${formData.countryCode}${formData.phoneNumber}`,
        },
        notes: {
            address: "Your Company Address",
        },
        theme: {
            color: "#21b65de4",
        },
    };

    const razor = new window.Razorpay(options);
    razor.open();
    setLoading(false);
};

  const checkoutHandler = async () => {
    setLoading(true);
    try {
      setError({});
      await validationSchema.validate(formData, { abortEarly: false });

      const customerResponse = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.addCustomer}`,
        formData
      );

      if (customerResponse.data.successCode !== 1) {
        throw new Error("Failed to create customer");
      }
      const customerId = customerResponse.data.result._id;
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.createOrder}`,
        {
          productId: carts.map(item => item._id),
          subtotal: subtotal + shippingCost
        }
      );
      if (response.data.successCode === 1) {
        const order = response.data.order;
        setOrder(order);
        initializeRazorpay(order, customerResponse);
      }
    } catch (err) {
      const newErrors = {};

      if (err.inner && Array.isArray(err.inner)) {
        err.inner.forEach(err => {
          newErrors[err.path] = err.message;
        });
      } else {
        newErrors.general = err.message || "Validation failed";
      }

      setError(newErrors);
      setLoading(false);
    }
  };
  const removeExistingCoupon = () => {
    try {
      setCouponCode("");
      dispatch(removeCoupon());
      setCouponApplied(false);
      toast.success("Coupon removed successfully!");
    } catch (err) {
      toast.error("Failed to remove coupon");
      console.error(err);
    }
  };
  const applyCoupon = async () => {
    try {
      if (couponApplied) {
        toast.warning("Coupon already applied!");
        return;
      }
      const response = await axios.get(
        `${BASE_URL}${API_ENDPOINTS.getCouponByCode}/${couponCode}`
      );

      if (response.data.successCode === 1) {
        const coupon = response.data.resultObject;
        const percentage = coupon.discountPercentage;
        const { maxDiscountInRs } = coupon;
        const calculatedDiscountAmount = (subtotal * percentage) / 100;

        toast.success("Coupon applied successfully!");
        if (calculatedDiscountAmount > maxDiscountInRs) {
          const percentageOfMaxDiscount = maxDiscountInRs / 100;

          dispatch(
            changeDiscount({
              discountPercentage: percentage,
              maxDiscountInRs: maxDiscountInRs,
            })
          );
        } else {
          // setDiscount(calculatedDiscountAmount);
          dispatch(
            changeDiscount({
              discountPercentage: percentage,
              maxDiscountInRs: maxDiscountInRs,
            })
          );
        }
        setCouponApplied(true);
      } else {
        toast.error("Invalid coupon code");
      }
    } catch (err) {
      toast.error("Error applying coupon");
      console.log(err);
    }
  };

  return (
    <div className="checkout-container">
      {loading && <Loader />}
      <div className="back-button">
        <button onClick={() => window.history.back()}>&larr; Back</button>
      </div>

      <div className="content">
        <div className="order-summary">
          <h2>Order Summary</h2>
          {carts.map(cartItem => (
            <div className="item" style={{ position: "relative" }}>
              <img src={cartItem.imgData} alt="Sunscreen stick" />
              <div className="item-details">
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  {cartItem.productName}
                </p>
                <div className="quantity-remove">
                  <button
                    className="remove-btn"
                    onClick={() => handleDecreaseCart(cartItem)}
                  >
                    -
                  </button>
                  <span>{cartItem.quantity}</span>
                  <button className="remove-btn" onClick={() => send(cartItem)}>
                    +
                  </button>
                </div>
              </div>

              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  marginBottom: "36px",
                  marginLeft: "30px",
                }}
              >
                {cartItem.price}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "65%",
                  left: "94%",
                  color: "rgba(108, 108, 108, 1)",
                  fontSize: "16px",
                }}
              >
                <RiDeleteBin7Line
                  onClick={() => handleRemoveFromCart(cartItem._id)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          ))}

          <div className="form-row">
            <label
              style={{
                fontWeight: "500",
                fontSize: "15px",
                lineHeight: "30px",
                color: "rgba(0, 0, 0, 1)",
                fontFamily: "Roboto",
              }}
            >
              Gift Card / Discount code
            </label>
          </div>
          <div className="form-row">
            <div
              className="form-group"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <input
                type="text"
                id="giftGroup"
                style={{ width: "400px" }}
                value={couponCode}
                onChange={e => setCouponCode(e.target.value)}
              />
              <button
                className={!couponApplied ? "apply-btn" : "remove-coupon-btn"}
                onClick={!couponApplied ? applyCoupon : removeExistingCoupon}
              >
                {!couponApplied ? "Apply" : "Remove"}
              </button>
            </div>
          </div>
          <div className="summary-details">
            <div className="summary-item">
              <span>Subtotal</span>
              <span>₹{subtotal + discountAmount}</span>
            </div>
            <div className="summary-item">
              <span>Discount</span>
              <span>₹{discountAmount}</span>
            </div>

            <div className="summary-item">
              <span>Shipping Fee</span>
              <span style={{ color: shippingCost === 0 ? "rgba(111, 135, 81, 1)" : "inherit" }}>
            {shippingCost === 0 ? "FREE" : `₹${shippingCost}`}
          </span>
            </div>
            <div className="total">
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  lineheight: "20px",
                  fontWeight: 600,
                }}
              >
                Total due
              </span>
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: 600,
                }}
              >
               ₹{subtotal + shippingCost}
              </span>
            </div>
          </div>
        </div>

        <div className="shipping-details">
          <form>
            <div className="details mb">
              <h2
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
              >
                Contact Details
              </h2>
              <div className="form-row">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    onChange={onChangeHandler}
                    value={formData.firstName}
                  />
                  {error.firstName && (
                    <div className="error">{error.firstName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={onChangeHandler}
                    value={formData.lastName}
                  />
                  {error.lastName && (
                    <div className="error">{error.lastName}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={onChangeHandler}
                  />
                  {error.email && <div className="error">{error.email}</div>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Phone Number</label>
                  <div className="phone-number">
                    <select
                      name="countryCode"
                      value={formData.countryCode}
                      onChange={onChangeHandler}
                    >
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="text"
                      name="phoneNumber"
                      onChange={onChangeHandler}
                      value={formData.phoneNumber}
                    />
                  </div>
                  {error.phoneNumber && (
                    <>
                      <div className="error">{error.phoneNumber}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="details shipping ">
              <h2
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
              >
                Shipping Details
              </h2>
              <div className="form-row">
                <div className="form-group">
                  <label>Flat/House no.</label>
                  <input
                    type="text"
                    name="flatHouseNo"
                    value={formData.flatHouseNo}
                    onChange={onChangeHandler}
                  />
                  {error.flatHouseNo && (
                    <div className="error">{error.flatHouseNo}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={onChangeHandler}
                  />
                  {error.address && (
                    <div className="error">{error.address}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={onChangeHandler}
                  />
                  {error.city && <div className="error">{error.city}</div>}
                </div>
                <div className="form-group">
                  <label>State</label>
                  <select
                  name="state"
                  value={formData.state}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                  {error.state && <div className="error">{error.state}</div>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={onChangeHandler}
                  />
                  {error.postalCode && (
                    <div className="error">{error.postalCode}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Famous Landmark</label>
                  <input
                    type="text"
                    name="famousLandmark"
                    value={formData.famousLandmark}
                    onChange={onChangeHandler}
                  />
                  {error.famousLandmark && (
                    <div className="error">
                      {error.famousLandmark && "Famous Landmark is required"}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <button
                onClick={carts.length > 0 ? checkoutHandler : undefined}
                className="btn-pay"
                type="button"
                disabled={carts.length === 0}
              >
                Pay
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* {order && (
        <RazorpayComponent order={order} onSuccess={handlePaymentSuccess} />
      )} */}
      <ToastContainer />
    </div>
  );
}

export default CheckOut;
