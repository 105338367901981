import { Menu, Typography, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MegaMenu() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const onMenuItemClick = (item) => {
    setSelectedKeys([item.key]); // Update the selected keys
    navigate(item.key); // Navigate to the route associated with the key
    window.location.href = item.key; // Force a page reload
  };

  useEffect(() => {
    const pathKey = location.pathname.split("/").pop(); // Get the last part of the URL
    setSelectedKeys([pathKey]); // Update selected key based on current path
  }, [location.pathname]);

  // Define menu items for reusability
  const menuSections = [
    {
      title: "All Products",
      key: "all-products",
      items: [
        { label: "All Products", key: "#" },
        { label: "Cleansers", key: "/Cleansers/67dd021dddfdc435870ab020" },
      ],
    },
    {
      title: "Skin Care",
      key: "skin-care",
      items: [
        { label: "Skin Care", key: "SkinCare", type: "group" },
        { label: "Toners", key: "/Toners/67dd025fddfdc435870ab02e" },
        { label: "Moisturizers", key: "/Moisturiser/67dd13acddfdc435870ab0ef" },
        { label: "Sunscreen", key: "/Sunscreen%20cream/67dd03dbddfdc435870ab04c" },
        { label: "Lipcare", key: "/Lip%20care/67dd048dddfdc435870ab060" },
        { label: "Serums", key: "/Serum/67dd033addfdc435870ab040" },
        { label: "Cleansers", key: "/Cleansers/67dd021dddfdc435870ab020" },
      ],
    },
    {
      title: "Haircare",
      key: "haircare",
      items: [
        { label: "Haircare", key: "Haircare", type: "group" },
        { label: "Hair Care", key: "/Hair%20care/67dd04c1ddfdc435870ab066" },
      ],
    },
    {
      title: "Skin Type",
      key: "skin-type",
      items: [
        { label: "Skin type", key: "SkinType", type: "group" },
        { label: "How to check your skin type?", key: "/skintype" },
        { label: "To know more about skin", key: "/skintype2" },
      ],
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px 10px",
        overflow: "visible", // Ensure no overflow issues
      }}
      key={location.pathname}
    >
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        Products List
      </Typography.Title>
      <Row justify="space-around" align="top">
        {menuSections.map((section) => (
          <Col xs={24} sm={12} lg={6} key={section.key}>
            <Menu
              key={`${section.key}-${location.pathname}`} // Unique key for each menu
              onClick={onMenuItemClick}
              selectedKeys={selectedKeys}
              mode="inline" // Use inline mode for both desktop and mobile
              style={{
                border: "none", // Remove default border
                width: "100%", // Ensure menu takes full width of the Col
              }}
              items={section.items}
            />
          </Col>
        ))}
      </Row>

      {/* Add custom CSS for mobile */}
      <style jsx>{`
        @media (max-width: 576px) {
          .ant-menu-inline {
            width: 100% !important; /* Ensure menu takes full width on mobile */
            border-right: none !important; /* Remove border on mobile */
          }
          .ant-menu-item-group-title {
            font-size: 16px !important; /* Adjust font size for mobile */
            padding: 10px 16px !important; /* Adjust padding */
          }
          .ant-menu-item {
            font-size: 14px !important; /* Adjust font size for menu items */
            padding: 5px 16px !important; /* Adjust padding */
          }
          .ant-row {
            margin: 0 !important; /* Remove default margin */
          }
          .ant-col {
            padding: 0 10px !important; /* Adjust padding for columns */
          }
        }
      `}</style>
    </div>
  );
}

export default MegaMenu;