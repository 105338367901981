// export const BASE_URL = 'https://noura-be.onrender.com'
// export const BASE_URL = 'http://localhost:4000'
export const BASE_URL = 'https://api.nourakoreanskincare.com'


export const API_ENDPOINTS = {
    getBrands: '/api/v1/brands/getBrands',

    getAllProducts: '/api/v1/products/getProducts',
    bestSellers: '/api/v1/products/getBestSellersProduct',
    getCategories: '/api/v1/category/getCategory',
    getProducts: '/api/v1/products/getProductByCategory',
    getProductsByBrands: '/api/v1/products/getProductByBrand',
    addCustomer: '/api/v1/customers/addCustomer',
    createOrder: '/api/v1/sales/createOrder',
    addSales: "/api/v1/sales/addSales",
    getCouponByCode: '/api/v1/coupon/getCouponByCode',
    getProductsById: '/api/v1/products/getProductById',
    sendEmail: '/api/v1/contactus/sendMail'

}
