import React, { useEffect, useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../skin Images/logo-background.png";
import MegaMenu from "./MegaMenu";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import { FaShoppingCart } from "react-icons/fa";
let navOptions = [
  { id: 1, name: "Home", label: "Home", link: "" },
  { id: 2, name: "Products", label: "Products", link: "" },
];

const Navbar = () => {
  const [isActive, setIsActive] = useState("home");
  const [nav, setNav] = useState(false);
  const [megaMenu, setMegaMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getAllProducts}`
        );
        setProducts(response.data.result);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      if (products && Array.isArray(products)) {
        const filtered = products.filter((product) =>
          product.productName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredProducts(filtered);
      }
    } else {
      setFilteredProducts([]);
    }
  }, [searchQuery, products]);

  const handleNav = () => setNav(!nav);

  const handleProductsClick = () => {
    setMegaMenu(!megaMenu);
    setNav(false); // Close mobile menu when opening MegaMenu
  };

  const closeMegaMenu = (e) => {
    if (
      !e.target.closest(".mega-menu") &&
      !e.target.closest(".topbarList") &&
      !e.target.closest(".mobile-nav") &&
      !e.target.closest(".mega-menu-close-btn")
    ) {
      setMegaMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMegaMenu);
    return () => {
      document.removeEventListener("click", closeMegaMenu);
    };
  }, []);

  return (
    <div className="navbarContainer">
      <div className="navbarLeft">
        <Link to="/">
          <img className="logo-img" src={Logo} alt="Logo" />
        </Link>
      </div>
      <div
        style={{ marginBottom: "0px", display: "flex", alignItems: "center" }}
        className={nav ? "navbar navbar-bg" : "navbar"}
      >
        <ul className="nav-menu" style={{ listStyle: "none" }}>
          {navOptions.map((option) => (
            <li className="topbarList" key={option.id}>
              <Link
                to={`/${option.link}`}
                className={`ListCoursesTopbar ${
                  isActive === option.name ? "activeClass" : ""
                }`}
                onClick={() => {
                  setIsActive(option.name);
                  if (option.name === "Products") handleProductsClick();
                }}
              >
                {option.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <div className="search-results">
              <ul>
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <li key={product._id}>
                      <Link
                        to={`/product/${product._id}`}
                        onClick={() => setSearchQuery("")}
                      >
                        {product.productName}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li>No products found</li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/contactUs"
            onClick={() => setIsActive("contactUs")}
            className={`ListCoursesTopbar ${
              isActive === "contactUs" ? "activeClass" : ""
            }`}
          >
            <button className="btn-contact">Contact Us</button>
          </Link>
          <Link
            to="/cart"
            onClick={() => setIsActive("cart")}
            className={`ListCoursesTopbar ${
              isActive === "cart" ? "activeClass" : ""
            }`}
          >
            <FaShoppingCart size="30px" />
          </Link>
        </div>
      </div>
      <div className="hamburger" onClick={handleNav}>
        {!nav ? (
          <HiOutlineMenuAlt4 className="icon" style={{ color: "black" }} />
        ) : (
          <AiOutlineClose style={{ color: "black" }} className="icon" />
        )}
      </div>
      <div className={nav ? "mobile-menu active" : "mobile-menu"}>
        <ul className="mobile-nav" style={{ listStyle: "none" }}>
          {navOptions.map((option) => (
            <li
              key={option.id}
              onClick={() => {
                handleNav();
                if (option.name === "Products") {
                  handleProductsClick();
                }
              }}
            >
              <Link to={`/${option.link}`}>{option.label}</Link>
            </li>
          ))}
          <li onClick={handleNav}>
            <Link to="/contactUs">Contact Us</Link>
          </li>
          <li onClick={handleNav}>
            <Link to="/cart">Cart</Link>
          </li>
        </ul>
      </div>
      {/* Overlay for MegaMenu */}
      {megaMenu && (
        <div
          className="mega-menu-overlay"
          onClick={() => setMegaMenu(false)}
        ></div>
      )}
      <div className={megaMenu ? "mega-menu active" : "mega-menu"}>
        {/* Close Button for Mobile */}
        <div className="mega-menu-close-btn" onClick={() => setMegaMenu(false)}>
          <AiOutlineClose size={24} />
        </div>
        <MegaMenu />
      </div>
    </div>
  );
};

export default Navbar;